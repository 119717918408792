import React from 'react'
import Title from '../Title'
import styles from '../../css/about.module.css'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

const getAboutImg = graphql`
query aboutImg {
  aboutImg: file(relativePath: {eq: "aboutBcg.jpeg"}) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
}`;

const About = () => {
  const { aboutImg } = useStaticQuery(getAboutImg);
  return (
    <section className={styles.about}>
      <Title title="Wie zijn" subtitle="wij" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img fluid={aboutImg.childImageSharp.fluid} alt="Over ons" />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <p>De stichting Met Uitgestrekte Hand is opgericht om zich in te zetten voor de allerarmsten en hulpbehoevende ouderen in Moldavië, Roemenië en in de Oekraïne. De stichting doet dit vanuit Bijbelse overtuiging.</p>
          <p>Wij doen dit met behulp van vele vrijwilligers in zowel Nederland als in de hiervoor genoemde landen. Deze mensen zijn voor de stichting Met Uitgestrekte Hand onmisbaar. Zij hebben geen naam die genoemd wordt in kranten en journaals, maar zij staan altijd klaar en zetten zich met hart en ziel in voor de goede zaak. Dat doen ze met veel liefde, aandacht en gedrevenheid.</p>
          <p>Voor de verwerking van materiële hulp kan de stichting in Nederland rekenen op  vrijwilligers die altijd klaar staan om dit in te zamelen en tijdelijk op te slaan. Voor financiële steun kan de stichting rekenen op vele donateurs die het werk van de stichting een warm hart toedragen. In samenspraak met enkele betrouwbare aanspreekpunten in de Oekraïne, Roemenië en Moldavië wordt gezorgd dat alle financiële en materiële hulp vanuit Nederland een juiste bestemming vindt. In de Oekraïne, Roemenië en Moldavië wordt dit vervolgens door de aanspreekpunten en vrijwilligers gedistribueerd.</p>
          <p>Het bestuur van de stichting bestaat uit tenminste drie personen die het dagelijks bestuur vormen.</p>
          <ul>
            <li>Voorzitter: Bert van Panhuis</li>
            <li>Secretaris: Ineke Pothof </li>
            <li>Penningmeester: Henk van de Berg </li>
            <li>Bestuurslid: Dick Teerling</li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default About
