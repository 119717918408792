import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Title from '../Title'
import styles from '../../css/services.module.css'
import services from '../../constants/services'
import { FaEuroSign } from "react-icons/fa"

const Services = () => {
    return (
        <section className={styles.services}>
            <Title title="over" subtitle="ons" />
            <div className={styles.center}>
                {
                    services.map((item, index) => {
                        return <article key={index} className={styles.service}>
                            <span>{item.icon}</span>
                            <h4>{item.title}</h4>
                           <ul>
                               {
                                   item.text.map((text, index) => {
                                        return <li key={index} className={styles.service} >
                                            {text}
                                        </li>
                                   })
                               }
                           </ul>
                        </article>
                    })
                }
                <article className={styles.service}>
                    <span><FaEuroSign /></span>
                    <h4>Financiën</h4>
                    <ul>
                        <li>
                            Dankzij vele donaties kunnen wij veel van onze doelen verwezenlijken. <AniLink className={styles.link} fade to={"/financieel"}>Lees hier</AniLink> wat we met deze donaties doen en bereiken voor verstandelijk beperkte, eenzame en minder bedeelde mensen, ziekenhuizen, artsen, scholen, bejaardentehuizen en tehuizen voor kinderen in Oost Europese landen
                        </li>
                    </ul>
                </article>
            </div>
        </section>
    )
}

export default Services
