import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import About from '../components/Home/About';
import Services from '../components/Home/Services';
import Donate from '../components/Home/Donate';
import FeaturedProjects from '../components/Home/FeaturedProjects';
import NoSupport from '../components/NoSupport';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';
import { isIE } from 'react-device-detect';

export default ({ data: { img } }) => (
	<Layout>
		<Seo title='Home' />
		{isIE ? <NoSupport /> : null}
		<div>
			<StyledHero isHome img={img.childImageSharp.fluid}>
				<Banner
					title='Stichting Met uitgestrekte hand'
					subtitle={`Laten we het goede doen, zonder op te geven, want als we niet verzwakken zullen we oogsten wanneer de tijd daarvoor gekomen is. Laten we dus, in de tijd die ons nog rest, voor iedereen het goede doen, vooral voor onze geloofsgenoten. 
			(Galaten 6: 9 en 10)`}
				>
					<AniLink fade className='btn-white' to='/projecten'>
						Onze Projecten
					</AniLink>
				</Banner>
			</StyledHero>
			<About />
			<Services />
			<Donate />
			<FeaturedProjects />
		</div>
	</Layout>
);

export const query = graphql`
	query {
		img: file(relativePath: { eq: "defaultBcg.jpeg" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 4160) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
