import React from 'react'
import Project from '../Projects/Project'
import { useStaticQuery, graphql } from 'gatsby'
import Title from '../Title'
import styles from '../../css/items.module.css'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const getProjects = graphql`
    query {
        latestProjects:allContentfulProjecten(limit: 3, sort: { fields: [createdAt], order: DESC }) {
            edges {
                node {
                    project,
                    land,
                    slug,
                    contentful_id,
                    afbeeldingen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
    }
`;

const FeaturedProjects = () => {
    const result = useStaticQuery(getProjects);
    const projects = result.latestProjects.edges;

    return (
        <section className={styles.projectsAlt }>
           <Title title="Laatste" subtitle="projecten" /> 
           <div className={styles.center}>
           {
               projects.map( ({node}) => {
                return <Project key={node.contentful_id} singleProject={node} />
               })
           }
           </div>
           <AniLink fade to='/projecten' className="btn-primary">Alle projecten</AniLink>
        </section>
    )
}

export default FeaturedProjects
