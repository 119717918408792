import React from 'react';
import Title from '../Title';
import styles from '../../css/donate.module.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Donate = () => {
	return (
		<section className={styles.donations}>
			<Title title='Wilt u' subtitle='doneren ?' />
			<div className={styles.center}>
				<p>
					Uw hulp is hard nodig, zodat wij de mensen in Oekraïne, Roemenië en Moldavië kunnen blijven helpen
					en projecten aldaar kunnen ondersteunen. Wij zouden het heel fijn vinden als u onze stichting wilt
					ondersteunen. U kunt dat doen met zowel giften, kleding en goederen.
				</p>
				<p>
					U kunt uw bijdrage storten op ons rekeningnummer <span className={styles.iban}>NL36SNSB 0851 6650 39</span> o.v.v. uw naam en adres. Wilt
					u kleding of goederen doneren neem dan contact met ons op.
				</p>
			</div>
			<div className={styles.btnWrapper}>
				<AniLink fade to='/contact' className='btn-primary'>
					Contact
				</AniLink>
			</div>
		</section>
	);
};

export default Donate;
