import React from "react"
import { FaBullseye, FaPrayingHands, FaEye, FaBible } from "react-icons/fa"

export default [
  {
    icon: <FaBullseye />,
    title: "Missie",
    text:
      ["De stichting Met Uitgestrekte Hand aanvaardt de Bijbel als het door God geïnspireerde en onfeilbare Woord als leidraad voor geloof en leven, uitgaande van het schriftgedeelte: Laten wij niet moede worden goed te doen, want wanneer het eenmaal tijd is zullen wij oogsten als wij niet verslappen. Laten wij dus, daar wij de gelegenheid hebben, doen wat goed is voor allen, maar inzonderheid voor onze geloofsgenoten. "]
  },
    {
    icon: <FaEye />,
    title: "Visie",
    text:
      ["Mensen in de Oekraïne, Roemenië en Moldavië vanuit de liefde van Christus ondersteunen met geestelijke, sociale, materiële, financiële en praktische hulp. Steun verlenen aan instellingen met een gelijksoortige doelstelling en zelfde grondslag. "]
  },
    {
    icon: <FaBible />,
    title: "Doelstelling",
    text: ["De stichting tracht haar doelen te verwezenlijken met gebed, sponsoracties, financiële acties, promotie, presentaties en mailingen. "]
  },
  {
    icon: <FaPrayingHands />,
    title: "Doelen",
    text: ['- verspreiden van de Bijbel en het Evangelie in Oost Europese landen',
          '- verzorgen van hulpmateriaal voor Oekraïne, Moldavië en Roemenië o.a. door transporten met hulpgoederen',
          '- financieel ondersteuning en materiële hulp aan kerken, ziekenhuizen. artsen, scholen, bejaardentehuizen, tehuizen voor kinderen, verstandelijk beperkte, eenzame en minder bedeelde mensen',
          '- financiële ondersteuning voor straatkinderen in Oekraïne en een evangelisch jeugdkamp',
          '- kerstpakkettenactie',
          '- financiële ondersteuning predikanten in Roemenië, Oekraïne en Moldavië',
          '- extra aandacht voor humanitaire en financiële ondersteuning van het oorlogsgebied Donetsz in de Oekraïne'
        ]
  }
]
