import React from 'react';
import styles from '../css/noSupport.module.css';
import Title from '../components/Title'

const NoSupport = () => {
	return (
		<div className={styles.noSupport}>
			<div className={styles.center}>
                <Title title="Sorry uw browsers" subtitle="wordt niet ondersteund" />
                <p>Internet Explorer is een verouderde internetbrowser van Microsoft en Microsoft is inmiddels gestopt met het verder ontwikkelen van deze internetbrowser en levert tevens geen ondersteuning en updates meer. 
                    Als alternatief raden wij u <a className={styles.link} rel="noopener noreferrer" href="https://support.microsoft.com/nl-nl/help/4027741/windows-get-microsoft-edge" target="_blank">Microsoft Edge</a> of <a className={styles.link} rel="noopener noreferrer" href="https://www.google.com/intl/nl/chrome/" target="_blank">Google Chrome</a>  aangeboden.</p>
            </div>
		</div>
	);
};

export default NoSupport;
